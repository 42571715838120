import React, { useState } from 'react'
import * as Styled from './OneTrustPrivacyPolicy.styles'

const OneTrustPrivacyPolicy = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  if (typeof document !== 'undefined') {
    const appendScript = (script) => document.body.appendChild(script)

    const script = document.createElement('script')
    script.src =
      'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js'
    script.type = 'text/javascript'
    script.charSet = 'UTF-8'
    // unique per site
    script.id = 'otprivacy-notice-script'
    script.onload = () => setIsLoaded(true)

    const loadNotices = document.createElement('script')
    loadNotices.type = 'text/javascript'
    loadNotices.charSet = 'UTF-8'
    loadNotices.innerHTML = `
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices([
            'https://privacyportal-cdn.onetrust.com/11ccab12-653b-4e89-b206-f783168adc9b/privacy-notices/draft/3cda4466-c232-4876-b2b5-4788c957e4d4.json'
          ], false);
        });
      `

    appendScript(script)

    if (isLoaded) {
      appendScript(loadNotices)
    }
  }

  return (
    <Styled.OneTrustPrivacyPolicy>
      {!isLoaded && (
        <h2 className="loading">Cargando política de privacidad…</h2>
      )}
      <div
        id="otnotice-3cda4466-c232-4876-b2b5-4788c957e4d4"
        className="otnotice"
      />
    </Styled.OneTrustPrivacyPolicy>
  )
}

export default OneTrustPrivacyPolicy
