import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'
import { Main } from '@leshen/ui'
import OneTrustPrivacyPolicySpanish from '../components/OneTrustPrivacyPolicy/spanish'

const PrivacyPolicy = ({ data }) => {
  return (
    <Layout
      data={data}
      main={
        <Main>
          <OneTrustPrivacyPolicySpanish />
        </Main>
      }
    />
  )
}

PrivacyPolicy.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default PrivacyPolicy

export const query = graphql`
  query OneTrustPrivacyPolicySpanish($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
